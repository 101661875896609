// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
// Remove the extra shadow on input focus
.form-control {
 &:focus {
 box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
 }
}
